<template>
  <div id="page-educations">
    
    <b-card>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab @click.prevent="refresh_table">{{$t('PAGES.EDUCATIONS.EDUCATIONS')}}</v-tab>
        <v-tab>{{$t('PAGES.EDUCATIONS.FACULTIES')}}</v-tab>
        <v-tab>{{$t('COMMON.IMPORT')}}</v-tab>
        <v-tab>{{$t('COMMON.SETTINGS')}}</v-tab>

        <v-tab-item>
          <EducationEditorModal
            ref="educationEditorModal"
            :item="education"
            @created="created_education"
            @updated="updated_education"
          />

          <EducationTable 
            ref="educationTable"
            @create_education_clicked="create_education_clicked"
            @edit_education_clicked="edit_education_clicked"
            @deleteEducationClicked="deleteEducationClicked"
          />
        </v-tab-item>
        <v-tab-item>
          <FacultyEditor 
            ref="facultyEditor"
            @created="facultyCreated"
          />

          <FacultyTable
            ref="facultyTable"
            @createFacultyClicked="createFacultyClicked"
            @editFacultyClicked="editFacultyClicked"
            @deleteFacultyClicked="deleteFacultyClicked"
            
          />
        </v-tab-item>

        <v-tab-item>
          <EducationImportJobs class="mt-8" />
        </v-tab-item>

        
        <v-tab-item>
          <EducationSettings 
            class="mt-8"
            :settings="settings" 
          />
        </v-tab-item>

      </v-tabs>

    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import EducationTable from '@/view/pages/ml/education/EducationTable.vue';
import EducationEditor from '@/view/pages/ml/education/EducationEditor.vue';
import FacultyTable from '@/view/pages/ml/education/FacultyTable.vue';
import FacultyEditor from '@/view/pages/ml/education/FacultyEditor.vue';
import EducationSettings from '@/view/pages/ml/settings/EducationSettings.vue';
import EducationImportJobs from '@/view/pages/ml/education/EducationImportJobs.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import EducationEditorModal from '@/view/pages/ml/education/EducationEditorModal.vue';

export default {
  name: 'EducationPage',
  components: {
    EducationTable,
    EducationEditor,
    FacultyTable,
    FacultyEditor,
    EducationSettings,
    EducationImportJobs,
    EducationEditorModal
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings'])
  },
  mounted() {
    
  },
  data() {
    return {
      education: null
    };
  },
  watch: {
    
  },
  methods: {

    refresh_table() {
      console.log('refreshing table')
      this.$nextTick(() => {
        this.$refs['educationTable'].refresh();
      });
    },

    created_education(education) {
      this.refresh_table();

      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EDUCATIONS.CREATED'));

      this.$refs['educationEditorModal'].hide();
    },

    async updated_education(education) {

      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EDUCATIONS.UPDATED'));

      this.refresh_table();      
    },

    deleteEducationClicked(id) {
      axios 
        .delete(`/education/${id}`)
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EDUCATIONS.DELETED'));

            this.$refs['educationTable'].refresh();
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_DELETE'))
        });
    },

    async edit_education_clicked(id) {
      
      try {
        const education_response = await axios.get(`/education/${id}`);

        this.education = education_response.data;

        console.log('edit education', education_response.data);
        console.log('company_id', this.education.company_id);

        this.$refs['educationEditorModal'].show();

        window.scrollTo(0, 0);
      }
      catch (err) {
        console.error('error', err);
      }
      
    },

    editFacultyClicked(faculty) {
      this.$refs['facultyEditor'].edit(faculty);
    },

    facultyCreated(faculty) {
      this.$refs['facultyTable'].refresh();
    },


    create_education_clicked() {
      this.education = {};
      this.$refs['educationEditorModal'].show();
    },

    createFacultyClicked() {
      this.$refs['facultyEditor'].create();
    },
    
    deleteFacultyClicked(faculty) {
      axios 
        .delete(`/education/faculty/${faculty.id}`)
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.FACULTY_DELETED'));

            this.$refs['facultyTable'].refresh();
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_FACULTY_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_FACULTY_DELETE'))
        });
    }
  }
};
</script>

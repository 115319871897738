<template>
  <b-card body-class="pt-0 px-0">
    <Confirm
      title="Starta Import"
      message="Är du säker på att du vill starta importen?"
      ref="confirmJob"
      :type="'success'"
      @confirm="on_job_confirmed"
    />

    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      head-variant="light"
      hover
      :fields="import_job_headers"
      :items="import_jobs"
      :per-page="per_page"
      :current-page="current_page"
      @row-clicked="row_clicked"
    >
      <template #cell(status)="{ item }">
        <v-chip
          style="font-size: 11px; color: red; border-color: red;"
          v-if="item.status === 'CANCELLED' || item.status === 'ERROR' || item.status === 'FAILED'  || item.status.indexOf('FAIL') >= 0"
          color="red"
          outlined
        >
          {{ get_import_status_text(item.status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px; color: orange; border-color: orange;"
          v-if="item.status === 'RUNNING' || item.status === 'START' || item.status === 'RUN_FIRST_IMPORT' || item.status === 'RUN_FIN_IMPORT' || item.status.indexOf('RUN_') >= 0 || item.status.indexOf('VALID') >= 0"
          color="orange"
          outlined
        >
          {{ get_import_status_text(item.status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px; border-color: green; color: green;"
          v-if="item.status === 'FINISHED' || item.status === 'DONE_FINAL' || item.status.indexOf('DONE') >= 0"
          color="green"
          outlined
        >
          {{ get_import_status_text(item.status) }}
        </v-chip>
      </template>
    </b-table>

  </b-card>

</template>

<style lang="scss" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}


</style>

<script>
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';

export default {
  name: 'education-import-job-table',
  components: {
    Confirm
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'companies']),
  },
  mounted() {
    if (!this.company && this.companies) {
      this.company = this.companies.find(item => item.company_id === this.currentCompanyId);
    }

    if (!this.period && this.periods) {
      this.period = this.periods.find(item => item.id === this.currentPeriodId);
    }
  },

  // props
  props: {
    import_jobs: {
      type: Array,
      required: true
    }
  },
  emits: ['on_job_selected', 'new_import_job_clicked'],

  data() {
    return {
      intervalId: null,
      showEditor: false,
      period: null,
      company: null,
      showConfirm: true,
      form: {
        name: 'Ny körning',
        company_ids: [],
        period_ids: [],
        period_selection: 'SELECTION',
        company_selection: 'SELECTION',
        num_lookups_calc: 0,
        cost_sek: 0
      },

      import_job_headers: [
        {
          label: 'Filnamn',
          sortable: true,
          key: 'file.name'
        },
        {
          label: 'Skapat',
          sortable: true,
          key: 'created_at'
        },
        {
          label: 'Avslutat',
          sortable: true,
          key: 'finished_at'
        },
        {
          label: '# Hanterade',
          sortable: true,
          key: 'num_processed'
        },
        {
          label: '# OK',
          sortable: true,
          key: 'num_success'
        },
        {
          label: '# Fel',
          sortable: true,
          key: 'num_fail'
        },
        {
          label: 'Status',
          sortable: true,
          key: 'status'
        },
        {
          visible: true,
          label: '',
          key: 'actions'
        }
      ],
      headerProps: {
        sortByText: 'Sortera'
      },
      activeExpanded: [],
      closedExpanded: [],
      singleExpand: false,
      rowsPerPage: [100, 200, 500, -1],
      current_page: 1,
      per_page: 100,
      total_rows: 0,

      search: null,
      isDisabledStart: false,
    };
  },
  watch: {},

  methods: {
    row_clicked(row) {
      this.$emit('on_job_selected', row.id);
    },

    get_import_status_text(status) {
      return this.$t('EDUCATION.STATUSES.' + status) || this.$t('COMMON.UNKNOWN');
    },

    on_job_confirmed() {},

    new_import_job_clicked() {
      this.$emit('new_import_job_clicked');
    },

    onSubmit(){},
  }
};
</script>

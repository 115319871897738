<template>
  <b-form-select
    id="faculty-list-select"
    class="mr-sm-2 mx-0"
    v-model="local_faculty_id"
    :options="facultyOptions"
  ></b-form-select>
</template>

<script>
import axios from 'axios';

import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'FacultyList',
  props: ['faculty_id'],
  components: {

  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies'])
  },
  data() {
    return {
      local_faculty_id: null,
      facultyOptions: [],
      selectedFacultyId: null
    };
  },
  emits: ['update:faculty_id'],
  watch: {
    faculty_id() {
      this.local_faculty_id = this.faculty_id;
    },
    
    local_faculty_id(newValue) {
      this.$emit('update:faculty_id', newValue);
    },
  },
  mounted() {
    if (this.faculty_id) {
      this.local_faculty_id = this.faculty_id;
    }
    this.selectedFacultyId = this.preselectedFacultyId;
    this.loadFaculties();
  },
  methods: {
    loadFaculties() {
      axios
        .get(`/education/faculty`)
        .then(res => {
          if (res.status === 200) {
            this.facultyOptions = res.data.map(item => ({ value: item.id, text: item.name }));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta fakulteter');
        });
    },

  }
};
</script>

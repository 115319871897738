<template>
  <div>
    <ShopOrderSelectModal
      ref="shopOrderSelectModal"
      :shop_order="shop_order"
      @selectedShopItem="selectedShopItem"
    />

    <!--begin::Advance Table Widget 9-->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5 ml-2">
        <h3 class="card-title align-items-start flex-column">
          <!-- <span class="card-label font-weight-bolder text-dark">Alla artiklar</span> -->
        </h3>
        <div class="card-toolbar">
        <!--<a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="addShopItemClicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('COMMON.ADD')}}</a
          >-->
        </div>
      </div>

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3 table-responsive">
        <div class="d-flex justify-content-between">
          
        </div>
        <b-table
          id="shopitem-table"
          class="mt-3 cursor mh-100"
          :items="items"
          :fields="showFields"
          head-variant="light"
          sticky-header
        >
          <template #cell(actions)="data">
            <div class='d-flex justify-content-end'>
              
            </div>
          </template>
        </b-table>

      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

import ShopOrderSelectModal from "@/view/pages/ml/shop_order/ShopOrderSelectModal.vue";

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: "shop_order_item-table",
  props: ["items","shop_order"],
  emits: ['select_shop_item', 'addShopItemClicked', 'edit_shop_item_clicked', 'delete_shop_item_clicked'],
  components: {
    ShopOrderSelectModal
  },
  mixins: [toasts],
  methods: {
    selectedShopItem(shop_item_id, name) {
      this.$emit('select_shop_item', shop_item_id, name);
    },
    addShopItemClicked() {
      //this.$emit('addShopItemClicked');
      this.$refs['shopOrderSelectModal'].show();
    },
    editShopItemClicked(shop_item_id) {
      this.$emit('edit_shop_item_clicked', shop_item_id);
    },
    deleteShopItemClicked(shop_item_id) {
      this.$emit('delete_shop_item_clicked', shop_item_id);
    },
    maxLengthOfCell(str, length) {
      return str && str.length > length ? str.substring(0, length) + '...' : str;
    },
    updateItems() {

      this.$forceUpdate();

    },

  },
  data() {
    return {

      perPage: 25,
      currentPage: 1,
      options: [25, 50, 100],
      showFields: [
        { 
          key: 'name', 
          label: this.$t('SHOP_ORDER.ARTICLE'), 
          show: true, 
          sortable: true, 
          thClass: 'pl-7 w-100', 
          tdClass: 'pl-7',
          formatter: (_, __, item) => {
            return item.shop_item.name
          }
        },
        { 
          key: 'amount', 
          label: this.$t('SHOP_ORDER.AMOUNT'), 
          show: true, 
          sortable: true, 
          thClass: 'pl-7 w-100', 
          tdClass: 'pl-7',
          formatter: (_, __, item) => {
            return item.shop_item.amount
          }
        },
        { 
          key: 'actions', 
          label: '', 
          show: true, 
          sortable: false
        }
      ],
      filter: '',
      list: [

      ]
    };
  }
};
</script>

<template>
  <b-card
    v-if="editing"
    title=""
    class="mb-2"
    ref="edit-faculty"
    id="edit-faculty"
    hide-footer
  >
    <div class="education_editor">

      <b-form @submit.prevent="update_faculty" class="mt-8">

        <b-form-group id="input-group-faculty_id" label="ID" label-for="input-faculty_id">
          <b-form-input
            id="input-faculty_id"
            v-model="form.id"
            type="text"
            disabled
          />
        </b-form-group>

        <b-form-group id="input-group-name" :label="$t('PAGES.EDUCATIONS.FACULTY_NAME')" label-for="input-name">
          <b-form-input
            id="input-name"
            v-model="form.name"
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="input-group-descr"
          :label="$t('PAGES.EDUCATIONS.FACULTY_DESCR')"
          label-for="input-descr"
        >
          <b-form-textarea
            id="input-descr"
            v-model="form.descr"
            type="text"
            rows="5"
          />
        </b-form-group>

        <b-button type="submit" variant="primary" class="mt-8">{{$t('COMMON.SAVE')}}</b-button>

      </b-form>
    </div>
  </b-card>
</template>

<style lang="scss">


</style>

<script>
import 'vue-select/dist/vue-select.css';
import Select2 from 'v-select2-component';

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import ShopOrderItemTable from '@/view/pages/ml/shop_order/ShopOrderItemTable.vue';

import QuickSearchMemberWidget from '@/view/pages/ml/search/QuickSearchMemberWidget.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'faculty-editor',
  components: {
    Select2,
    Confirm,
    QuickSearchMemberWidget,
    ShopOrderItemTable
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
  },
  mixins: [ toasts ],
  mounted() {

  },
  data() {
    return {
      form: {},
      typeOptions: [
        { value: 'NONE', text: this.$t('PAGES.EDUCATIONS.TYPE_NONE') },
        { value: 'PROGRAM', text: this.$t('PAGES.EDUCATIONS.TYPE_PROGRAM') },
        { value: 'COURSE', text: this.$t('PAGES.EDUCATIONS.TYPE_COURSE') },
        { value: 'FREE_COURSE', text: this.$t('PAGES.EDUCATIONS.TYPE_FREE_COURSE') },
      ],
      creating: false,
      editing: false,

    };
  },
  props: [],
  emits: ['created', 'updated'],
  watch: {},
  methods: {
    edit(faculty) {
      this.form = faculty;
      this.editing = true;
    },

    create() {
      this.form = {};
      this.creating = true;
      this.editing = true;
    },

    async update_faculty(e) {
      if (e !== null) {
        e.preventDefault();
      }

      const loader = this.$loading.show();

      if (this.creating) {
        try {
          const res = await axios.post('/education/faculty', this.form);
          loader && loader.hide();
          if (res.status === 201) {
            this.creating = false;
            this.form = res.data;

            this.$emit('created', this.form);

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EDUCATIONS.FACULTY_CREATED'));
          }
        }
        catch (err) {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_FACULTY_CREATE'));
        }
      } else {
        try {
          const res = await axios.put(`/education/faculty/${this.form.id}`, this.form);
          loader && loader.hide();
          if (res.status === 204) {
            this.creating = false;
            this.$emit('updated', this.form);

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EDUCATIONS.FACULTY_UPDATED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_FACULTY_UPDATE'));
          }
        }
        catch (err) {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_FACULTY_UPDATE'));
        }
      }
    },
    resetForm() {
      this.form = {};
    },

  }
};
</script>

<template>
  <div class="px-5 mt-8" id="page-education">
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card title="" class="mb-2 mt-8" hide-footer>
          <b-form class="mt-8" @submit.prevent="saveData">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success mb-7">
              <input
                type="checkbox"
                name=""
                v-model="education.enabled"
                @click="education.enabled = !education.enabled"
              />
              <span class='mr-3'></span>
              {{ $t('EDUCATION.ACTIVATE_EDUCATIONS') }}
            </label>

            <b-button type="submit" variant="primary">{{ $t('COMMON.SAVE') }}</b-button>
          </b-form>
        </b-card></b-col
      >
    </b-row>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'education-settings',
  props: ['settings'],
  
  mixins: [ toasts ],

  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      education: {
        enabled: false
      }
    };
  },
  created(){
    if (this.settings && this.settings.education){
      this.education.enabled = this.settings.education.enabled;
    }
    else {
      this.education.enabled = false;
    }
  },
  methods: {
    saveData() {
      axios.put(`/company/company_settings/${this.currentCompanyId}`, {
        education: this.education
      }).then(res => {
        if (res.status === 200){
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }
      }).catch(err => {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
      })
    },

  }
};
</script>
